import { ZONE_SET_MODAL_ACTIONS } from "./zoneSetModal.actionTypes";
import { ZONE_SET_MODAL_INITIAL_STATE } from "./zoneSetModal.state";

export const zoneSetModalReducer = (state = ZONE_SET_MODAL_INITIAL_STATE, { type, data }) => {
    switch (type) {
        case ZONE_SET_MODAL_ACTIONS.SET_ZONE_SET_MODAL_OPEN:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    isZoneSetModalOpen: data.isZoneSetModalOpen,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.SET_UPLOAD_ZONE_SET_MODAL_OPEN:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    isUploadZoneSetModalOpen: data.isUploadZoneSetModalOpen,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.SET_VIEW_MODE:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    viewMode: data.viewMode,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.SET_UI_STATE:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    ...data,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.SET_SCREEN_MODE:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    screenMode: data.screenMode,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.UPDATE_ZONE_SET:
            return {
                ...state,
                zoneSet: {
                    ...state.zoneSet,
                    ...data.zoneSet,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.SET_IS_LOADING:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    isLoading: data.isLoading,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.SET_LINKED_ZONE_SET_MODAL_OPEN:
            return {
                ...state,
                uiStates: {
                    ...state.uiStates,
                    isLinkedZoneSetsModalOpen: data.isLinkedZoneSetsModalOpen,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.SET_ZONE_SET_NAME:
            return {
                ...state,
                zoneSetName: {
                    ...state.zoneSetName,
                    ...data.zoneSetName,
                },
            };

        case ZONE_SET_MODAL_ACTIONS.SET_HOVERED_ZONE: {
            return {
                ...state,
                map: { ...state.map, hoveredZone: data.zone },
            };
        }

        case ZONE_SET_MODAL_ACTIONS.SET_HOVERED_BUS_ZONES: {
            return {
                ...state,
                map: { ...state.map, hoveredBusZones: data.zones },
            };
        }

        case ZONE_SET_MODAL_ACTIONS.SET_MAP_ERROR: {
            return {
                ...state,
                map: { ...state.map, mapError: data.error },
            };
        }

        case ZONE_SET_MODAL_ACTIONS.RESET_ZONE_SET_MODAL_STATE:
            return ZONE_SET_MODAL_INITIAL_STATE;

        case ZONE_SET_MODAL_ACTIONS.SET_SELECTED_ZONE_SET_ID:
            return {
                ...state,
                selectedZoneSetId: data.selectedZoneSetId,
            };

        case ZONE_SET_MODAL_ACTIONS.SET_SELECTED_ZONE_IDS:
            return {
                ...state,
                selectedZoneIds: data.selectedZoneIds,
            };

        default:
            return state;
    }
};
