import { Expression } from "mapbox-gl";
import { ERiskLevels, FEMA_SUB_LAYERS, FEMA_SUB_LAYERS_LIST } from "./mapEnhancedLayers.constants";

const createNRILayerConfig = (layerCode: string) => ({
    color: "#F52441",
    filter: [
        "any",
        ["==", ["get", layerCode], ERiskLevels.VERY_HIGH],
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_HIGH],
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_MODERATE],
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_LOW],
        ["==", ["get", layerCode], ERiskLevels.VERY_LOW],
    ] as Expression,
    fillOpacity: [
        "case",
        ["==", ["get", layerCode], ERiskLevels.VERY_HIGH],
        0.9,
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_HIGH],
        0.7,
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_MODERATE],
        0.5,
        ["==", ["get", layerCode], ERiskLevels.RELATIVELY_LOW],
        0.3,
        ["==", ["get", layerCode], ERiskLevels.VERY_LOW],
        0.2,
        1,
    ] as Expression,
});

const createRangeExpression = (layerCode: string, min: number, max: number): Expression => [
    "all",
    [">=", ["get", layerCode], min],
    ["<=", ["get", layerCode], max],
];

const createNHLLayerConfig = (layerCode: string, ranges: number[]) => ({
    color: "#1073E9",
    filter: ["any", ["all", [">=", ["get", layerCode], 0]]] as Expression,
    fillOpacity: [
        "case",
        createRangeExpression(layerCode, 0, ranges[0]),
        0.2,
        createRangeExpression(layerCode, ranges[0], ranges[1]),
        0.3,
        createRangeExpression(layerCode, ranges[1], ranges[2]),
        0.5,
        createRangeExpression(layerCode, ranges[2], ranges[3]),
        0.7,
        ["all", [">", ["get", layerCode], ranges[3]]],
        0.9,
        1,
    ] as Expression,
});

export const getFEMALayerExpression = (layerCode: string, riskLevel: string) => {
    const femaLayer = FEMA_SUB_LAYERS_LIST.find(item => item.visField === layerCode);

    if (femaLayer && femaLayer.mapLayerBins.length) {
        const ranges = femaLayer.mapLayerBins;

        switch (riskLevel) {
            case ERiskLevels.VERY_LOW:
                return createRangeExpression(layerCode, 0, ranges[0]);
            case ERiskLevels.LOW:
                return createRangeExpression(layerCode, ranges[0], ranges[1]);
            case ERiskLevels.MODERATE:
                return createRangeExpression(layerCode, ranges[1], ranges[2]);
            case ERiskLevels.HIGH:
                return createRangeExpression(layerCode, ranges[2], ranges[3]);
            case ERiskLevels.VERY_HIGH:
                return ["all", [">", ["get", layerCode], ranges[3]]];
            default:
                return ["all", [">=", ["get", layerCode], 0]]; // Default filter
        }
    }

    return ["==", ["get", layerCode], riskLevel];
};

export const getFEMALayerStyleConfig = (layerCode: string) => {
    const femaLayer = FEMA_SUB_LAYERS_LIST.find(item => item.visField === layerCode);
    return femaLayer && femaLayer.mapLayerBins.length
        ? createNHLLayerConfig(layerCode, femaLayer.mapLayerBins)
        : createNRILayerConfig(layerCode);
};

export const getFEMASubLayerById = (id: string) => {
    return Object.values(FEMA_SUB_LAYERS).find((subLayer: { id: string }) => subLayer.id === id);
};
