import {
    ZONE_SET_MODES,
    ZONE_SET_SCREEN_MODES,
} from "@app/bigBang/zoneSetModal/state/zoneSetModal.constants";

export const ZONE_SET_MODAL_INITIAL_STATE = {
    uiStates: {
        viewMode: ZONE_SET_MODES.CREATE,
        screenMode: ZONE_SET_SCREEN_MODES.CREATE_ZONE_SET,
        isLoading: false,
        isZoneSetModalOpen: false,
        isUploadZoneSetModalOpen: false,
        isLinkedZoneSetsModalOpen: false,
        isZonesTableCollapsed: false,
    },
    zoneSet: {},
    otherZoneSet: null,
    zoneSetName: {
        isEditMode: false,
        value: null,
    },
    map: {
        hoveredZone: null,
        hoveredBusZones: [],
        mapError: null,
    },
    selectedZoneIds: [],
};
