import classnames from "classnames";
import type { Map } from "mapbox-gl";
import { createButton } from "../customControls.helpers";

export class MapLayersControl {
    _map!: Map | undefined;
    _container!: HTMLDivElement;
    _showLayers = false;
    _onAdd: () => void;
    _button!: HTMLButtonElement;

    mapLayersContainer!: HTMLDivElement;

    constructor({ onAdd }: { onAdd: () => void }) {
        this._onAdd = onAdd;
    }

    onAdd = (map: Map) => {
        this._map = map;
        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group map-layers-control";

        const button = createButton({
            className: "mapboxgl-map-layers-button",
            tooltip: "Configure map layers",
            onClick: this.onShowLayers.bind(this),
        });
        this.mapLayersContainer = document.createElement("div");
        this.mapLayersContainer.className = classnames(
            "map-layers-wrapper",
            !this._showLayers && "hidden",
        );

        this._button = button;
        this._container.appendChild(button);
        this._container.appendChild(this.mapLayersContainer);

        this._onAdd();

        return this._container;
    };

    onRemove = () => {
        this._container.parentNode?.removeChild(this._container);
        this._map = undefined;
    };

    onShowLayers = () => {
        this._showLayers = !this._showLayers;
        this.mapLayersContainer.classList.toggle("hidden", !this._showLayers);
    };
}
